import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/blog-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Week 4`}</h1>
    <p>{`Last week of January! This week contains only new releases. It has some mind-bending braindance on a new compilation from Massage Brain Cult. De Stijl has released its second EP, and it already contains one of the best ambient tracks this year by Korridor. Aquarian released his first album on Beduoin Records, hard-hitting EBM by Phase Fatale and some lush electro by Credit 00.`}<div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe style={{
      "border": "0",
      "width": "180px",
      "height": "180px"
    }} src="https://bandcamp.com/EmbeddedPlayer/album=2237941557/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/" seamless>
  <a href="http://massagebraincult.bandcamp.com/album/1">
    √∆1 by Massage Brain Cult
  </a>
    </iframe>
    <h1>{`Massage Brain Cult - √∆1`}</h1>
    <p>{`Mind-bending braindance and interesting rhythms on this one`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://massagebraincult.bandcamp.com/album/1"
      }}>{`Bandcamp`}</a><div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe style={{
      "border": "0",
      "width": "180px",
      "height": "180px"
    }} src="https://bandcamp.com/EmbeddedPlayer/album=3899871562/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/track=3218964283/transparent=true/" seamless>
  <a href="http://de-stijl.bandcamp.com/album/de-stijl-02">
    De Stijl 02 by Korridor
  </a>
    </iframe>
    <h1>{`De Stijl 02`}</h1>
    <p>{`The second release on De Stijl contains four different tracks by various producers. Be sure to checkout Korridor his track which will transport you to another place.`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://de-stijl.bandcamp.com/album/de-stijl-02"
      }}>{`Bandcamp`}</a><div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe style={{
      "border": "0",
      "width": "180px",
      "height": "180px"
    }} src="https://bandcamp.com/EmbeddedPlayer/album=4238309305/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/track=571075164/transparent=true/" seamless>
  <a href="http://bedouinrecords.bandcamp.com/album/the-snake-that-eats-itself">
    The Snake That Eats Itself by Aquarian
  </a>
    </iframe>
    <h1>{`Aquarian – The Snake That Eats Itself`}</h1>
    <p>{`Nearly five years in the making, it’s Aquarian’s most complex and diverse work to date.`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://bedouinrecords.bandcamp.com/album/the-snake-that-eats-itself"
      }}>{`Bandcamp`}</a><div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe width="180" height="180" src="https://www.youtube.com/embed/R792FLBE2K4" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h1>{`Phase Fatale - Scanning Backwards`}</h1>
    <p>{`Phase Fatale’s second full-length album is using a connection between weaponized sound and psychological manipulation.`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://phasefatale.bandcamp.com/album/scanning-backwards"
      }}>{`Bandcamp`}</a><div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe style={{
      "border": "0",
      "width": "180px",
      "height": "180px"
    }} src="https://bandcamp.com/EmbeddedPlayer/album=305736697/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/" seamless>
  <a href="http://mechatronica.bandcamp.com/album/beats-for-the-streets-ep">
    Beats For The Streets EP by Credit 00
  </a>
    </iframe>
    <h1>{`Credit 00 - Beats For The Streets EP`}</h1>
    <p>{`Merciless machine funk, haunting soundscapes and rugged electro styles.`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://mechatronica.bandcamp.com/album/beats-for-the-streets-ep"
      }}>{`Bandcamp`}</a></p>
    <div style={{
      marginBottom: "80px"
    }} />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      